import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import AddToListButton from 'components/Lists/AddToListButton';
import { Card } from 'antd';
const { Meta } = Card;

const ProductContainer = styled.div`
  border: 1px solid #cfcfcf;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  width: 48%;
  margin: 1%;
  height: 200px;
  border-radius: 8px;
  display: flex;
  padding: 10px;
`;

const Price = styled.div`
  color: #ff0000;
`;
const Image = styled.img`
  border: 1px solid #cfcfcf;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  object-fit: contain;
  max-height: 250px;
  height: 250px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductPreview = ({ item }) => {
  // eslint-disable-next-line no-debugger
  console.log(item);
  return (
    <Card
      style={{ width: '100%' }}
      cover={<Image src={item.thumbailUrl} width="100%" alt={item.title} />}>
      <Meta title={item.title} description={item.title} />
    </Card>
  );
};

export default ProductPreview;
