import React, { useState, useEffect } from 'react';
import { Pagination, Empty } from 'antd';
import styled from 'styled-components';

import List from '../Lists/CarouselList';
import EventBus from 'common/EventBus';
import listService from 'services/list.service';

const ListsWrap = styled.div`
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  align-content: space-between;
`;

const UserLists = ({ userId, title = 'My Lists', numOfItems, noPagination, featured }) => {
  const [content, setContent] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(numOfItems || 9);

  const onPaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPerPage(pageSize);
  };
  const onListRemoved = (listId) => {
    setContent(content.filter((item) => item._id !== listId));
  };
  useEffect(() => {
    listService.getUserLists({ featured, userId, page: currentPage, perPage }).then(
      (response) => {
        setTotalCount(response.headers['x-total-count']);
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setContent(_content);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, [currentPage]);

  return (
    <div>
      <h1>{title}</h1>
      <ListsWrap>
        {content && content.map
          ? content.map((item, i) => {
              // Return the element. Also pass key
              return <List onListRemoved={onListRemoved} key={item._id} item={item} />;
            })
          : ''}
      </ListsWrap>
      {content && content.length === 0 ? <Empty /> : ''}
      {!noPagination && !totalCount && (
        <Pagination
          current={currentPage}
          onChange={onPaginationChange}
          simple
          pageSize={perPage}
          total={totalCount}
        />
      )}
    </div>
  );
};

export default UserLists;
