import axios from 'axios';

const API_URL =
  (process.env.REACT_APP_API_BASE === ''
    ? 'http://localhost:3020/'
    : process.env.REACT_APP_API_BASE) + 'playground/';

const generateImage = ({ imageUrl, prompt }) => {
  return axios.post(API_URL + 'generateImage', { imageUrl, prompt });
};

export default {
  generateImage
};
