import { Input } from 'antd';
import styled from 'styled-components';
export const StyledInput = styled(Input)`
  border: 1px solid #b2bec3;
  border-radius: 7px;
  min-height: 40px;
  min-width: 100%;
`;

export const StyledPasswordInput = styled(Input.Password)`
  border: 1px solid #b2bec3;
  border-radius: 7px;
  min-height: 40px;
  min-width: 100%;
`;
