export const uploadStateTypes = {
  UPLOADING: 'uploading',
  SUCCEED: 'succeed',
  CANCEL_SIGNALED: 'cancelSignaled',
  CANCELLED: 'cancelled',
  FAILED: 'failed'
};

export const fetchTypes = {
  UPLOAD: 'upload',
  DOWNLOAD: 'download'
};
