/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components';

const AliWrap = styled.div``;

export const AliIcon = () => (
  <AliWrap>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" width="15" height="15">
      <path
        d="M 1.171875 0 C 0.523438 0 0 0.523438 0 1.171875 L 0 13.828125 C 0 14.476562 0.523438 15 1.171875 15 L 13.828125 15 C 14.476562 15 15 14.476562 15 13.828125 L 15 1.171875 C 15 0.523438 14.476562 0 13.828125 0 Z M 1.171875 0 "
        fill="#e62e04"
      />
      <path
        d="M 4.125 5.027344 C 4.183594 5.261719 4.148438 5.515625 4.207031 5.753906 C 4.402344 5.609375 4.535156 5.371094 4.507812 5.125 C 4.503906 4.679688 4.003906 4.332031 3.585938 4.480469 C 3.324219 4.558594 3.132812 4.792969 3.085938 5.058594 C 3.035156 5.363281 3.21875 5.675781 3.488281 5.8125 C 3.460938 5.550781 3.398438 5.273438 3.46875 5.019531 C 3.578125 4.738281 4.027344 4.746094 4.125 5.027344 Z M 11.890625 5.34375 C 11.988281 5 11.777344 4.601562 11.4375 4.492188 C 11.109375 4.363281 10.703125 4.527344 10.558594 4.847656 C 10.398438 5.144531 10.496094 5.566406 10.789062 5.738281 C 10.847656 5.5 10.804688 5.246094 10.875 5.015625 C 10.984375 4.738281 11.425781 4.746094 11.527344 5.027344 C 11.589844 5.277344 11.53125 5.539062 11.507812 5.792969 C 11.710938 5.730469 11.835938 5.539062 11.890625 5.347656 Z M 11.890625 5.34375 "
        fill="#b32100"
      />
      <path
        d="M 10.875 5.015625 C 10.804688 5.246094 10.847656 5.5 10.789062 5.738281 C 10.617188 6.769531 9.929688 7.691406 8.992188 8.152344 C 8.050781 8.628906 6.9375 8.625 5.992188 8.148438 C 5.066406 7.6875 4.382812 6.773438 4.207031 5.753906 C 4.152344 5.515625 4.183594 5.265625 4.125 5.027344 C 4.027344 4.746094 3.578125 4.738281 3.46875 5.019531 C 3.398438 5.277344 3.464844 5.550781 3.488281 5.816406 C 3.6875 7.089844 4.539062 8.234375 5.695312 8.804688 C 6.769531 9.335938 8.027344 9.363281 9.125 8.882812 C 10.375 8.34375 11.308594 7.140625 11.511719 5.789062 C 11.535156 5.539062 11.59375 5.273438 11.527344 5.027344 C 11.425781 4.746094 10.984375 4.738281 10.875 5.015625 Z M 10.875 5.015625 "
        fill="#fff"
      />
      <path
        d="M 1.171875 0 C 0.523438 0 0 0.523438 0 1.171875 L 0 3.398438 C 0 2.75 0.523438 2.226562 1.171875 2.226562 L 13.828125 2.226562 C 14.476562 2.226562 15 2.75 15 3.398438 L 15 1.171875 C 15 0.523438 14.476562 0 13.828125 0 Z M 1.171875 0 "
        fill="#f90"
      />
    </svg>
  </AliWrap>
);
const xxx = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1">
    <g id="surface1">
      <path
        style=" stroke:none;fill-rule:nonzero;fill:rgb(90.196078%,18.039216%,1.568627%);fill-opacity:1;"
        d="M 1.171875 0 C 0.523438 0 0 0.523438 0 1.171875 L 0 13.828125 C 0 14.476562 0.523438 15 1.171875 15 L 13.828125 15 C 14.476562 15 15 14.476562 15 13.828125 L 15 1.171875 C 15 0.523438 14.476562 0 13.828125 0 Z M 1.171875 0 "
      />
      <path
        style=" stroke:none;fill-rule:nonzero;fill:rgb(70.196078%,12.941176%,0%);fill-opacity:1;"
        d="M 4.125 5.027344 C 4.183594 5.261719 4.148438 5.515625 4.207031 5.753906 C 4.402344 5.609375 4.535156 5.371094 4.507812 5.125 C 4.503906 4.679688 4.003906 4.332031 3.585938 4.480469 C 3.324219 4.558594 3.132812 4.792969 3.085938 5.058594 C 3.035156 5.363281 3.21875 5.675781 3.488281 5.8125 C 3.460938 5.550781 3.398438 5.273438 3.46875 5.019531 C 3.578125 4.738281 4.027344 4.746094 4.125 5.027344 Z M 11.890625 5.34375 C 11.988281 5 11.777344 4.601562 11.4375 4.492188 C 11.109375 4.363281 10.703125 4.527344 10.558594 4.847656 C 10.398438 5.144531 10.496094 5.566406 10.789062 5.738281 C 10.847656 5.5 10.804688 5.246094 10.875 5.015625 C 10.984375 4.738281 11.425781 4.746094 11.527344 5.027344 C 11.589844 5.277344 11.53125 5.539062 11.507812 5.792969 C 11.710938 5.730469 11.835938 5.539062 11.890625 5.347656 Z M 11.890625 5.34375 "
      />
      <path
        style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
        d="M 10.875 5.015625 C 10.804688 5.246094 10.847656 5.5 10.789062 5.738281 C 10.617188 6.769531 9.929688 7.691406 8.992188 8.152344 C 8.050781 8.628906 6.9375 8.625 5.992188 8.148438 C 5.066406 7.6875 4.382812 6.773438 4.207031 5.753906 C 4.152344 5.515625 4.183594 5.265625 4.125 5.027344 C 4.027344 4.746094 3.578125 4.738281 3.46875 5.019531 C 3.398438 5.277344 3.464844 5.550781 3.488281 5.816406 C 3.6875 7.089844 4.539062 8.234375 5.695312 8.804688 C 6.769531 9.335938 8.027344 9.363281 9.125 8.882812 C 10.375 8.34375 11.308594 7.140625 11.511719 5.789062 C 11.535156 5.539062 11.59375 5.273438 11.527344 5.027344 C 11.425781 4.746094 10.984375 4.738281 10.875 5.015625 Z M 10.875 5.015625 "
      />
      <path
        style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,60%,0%);fill-opacity:1;"
        d="M 1.171875 0 C 0.523438 0 0 0.523438 0 1.171875 L 0 3.398438 C 0 2.75 0.523438 2.226562 1.171875 2.226562 L 13.828125 2.226562 C 14.476562 2.226562 15 2.75 15 3.398438 L 15 1.171875 C 15 0.523438 14.476562 0 13.828125 0 Z M 1.171875 0 "
      />
    </g>
  </svg>
);
