import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Avatar, Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';

import EventBus from 'common/EventBus';
import userService from 'services/user.service';
import UserLists from './UserLists';

const UserImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
`;

const UserPage = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const isMobile = useMediaQuery({ query: ' (max-width: 768px)' });
  useEffect(() => {
    userService.getById({ userId }).then(
      (response) => {
        setUser(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, [userId]);

  return (
    <Row>
      <Col span={isMobile ? 24 : 6}>
        <UserImage src={user && user.profileImage} />
        <h1>{user ? `${user.firstname} ${user.lastname}` : ''}</h1>
        <div>
          <p>
            <b>{user?.lists?.length || 0}</b> Lists
          </p>
          <span>
            <b>{user?.products?.length || 0}</b> Products
          </span>
        </div>
      </Col>
      <Col span={isMobile ? 24 : 18}>
        <UserLists userId={userId} title={`${user?.firstname}'s lists:`} />
      </Col>
    </Row>
  );
};

export default UserPage;
