import React, { useRef, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Navigate, useNavigate, useParams, Link } from 'react-router-dom';
import { Tabs, Spin } from 'antd';
import { Button, Form, Input, InputNumber, Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';

import UserLists from './UserLists';
import Playground from '../Playground/Playground';
import userService from 'services/user.service';
import { StyledInput } from 'helpers/components';
import ProfileAvatar from './ProfileAvatar';
const { TabPane } = Tabs;

const ProfileMainWrap = styled.div`
  background: #f5f5f5;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  max-width: 1000px;
  @media only screen and (max-width: 768px) {
    width: 95%;
    margin: 5px 2.5%;
  }
  padding: 16px;
`;

const UpdateButton = styled(Button)`
  background: #656565;
  height: 40px;
  color: #fff;
  border-radius: 20px;
  border: 0px;
  font-weight: 600;

  margin-left: auto;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  &.ant-btn:hover {
    background: #656565;
    opacity: 0.8;
    color: #fff;
  }
`;

const StyledTabs = styled(Tabs)`
  @media only screen and (max-width: 768px) {
    .ant-tabs-nav {
      display: none;
    }
  }
`;

const AvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
`;
const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const form = useRef();
  const [user, setUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { tab: tabParam } = useParams();
  const isMobile = useMediaQuery({ query: ' (max-width: 768px)' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const loadUserData = async () => {
      const user = await userService.getById({ userId: currentUser.id });
      setUser(user.data.data);
    };
    if (currentUser) {
      loadUserData();
    }
  }, [currentUser.id, dispatch]);

  if (!currentUser) {
    return <Navigate to="/#login" />;
  }
  const onFinish = (values) => {
    setIsSubmitting(true);
    userService
      .updateUser(values)
      .then(() => {
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  };
  const handleTabClick = (key) => {
    navigate(`/profile/${key}`); // < == router router v4
  };

  return (
    <div className="">
      <div className="">
        <StyledTabs activeKey={tabParam} tabPosition={'left'} onChange={handleTabClick}>
          <TabPane tab="User Info" key="info" style={{ padding: 0 }}>
            <ProfileMainWrap>
              {user ? (
                <>
                  <Row>
                    <Col span={isMobile ? 24 : 6}>
                      <AvatarWrap>
                        <ProfileAvatar />
                        <div>
                          <p>
                            <b>{user?.lists?.length || 0}</b> Lists
                          </p>
                          <span>
                            <b>{user?.products?.length || 0}</b> Products
                          </span>
                        </div>
                        <Link to={`/users/${currentUser.id}`}>View Profile</Link>
                      </AvatarWrap>
                    </Col>

                    <Col span={isMobile ? 24 : 18}>
                      <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        ref={form}>
                        <Form.Item
                          name={['user', 'firstname']}
                          label="Firstname"
                          initialValue={currentUser.firstname}
                          rules={[{ required: true }]}>
                          <StyledInput />
                        </Form.Item>
                        <Form.Item
                          name={['user', 'lastname']}
                          label="Lastname"
                          initialValue={currentUser.lastname}
                          rules={[{ required: true }]}>
                          <StyledInput />
                        </Form.Item>
                        <Form.Item
                          name={['user', 'email']}
                          label="Email"
                          initialValue={currentUser.email}
                          rules={[{ type: 'email', required: true }]}>
                          <StyledInput />
                        </Form.Item>
                        {false && (
                          <Form.Item
                            name={['user', 'age']}
                            label="Age"
                            rules={[{ type: 'number', min: 0, max: 99 }]}>
                            <InputNumber />
                          </Form.Item>
                        )}
                        <Form.Item name={['user', 'website']} label="Website">
                          <StyledInput />
                        </Form.Item>
                        <Form.Item name={['user', 'introduction']} label="Introduction">
                          <Input.TextArea />
                        </Form.Item>
                        <div style={{ display: 'flex' }}>
                          <UpdateButton loading={isSubmitting} htmlType="submit">
                            Submit
                          </UpdateButton>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </>
              ) : (
                <Spin size="large" />
              )}
            </ProfileMainWrap>
          </TabPane>
          <TabPane tab="Lists" key="lists">
            <UserLists userId={currentUser.id} />
          </TabPane>
          {/*  <TabPane tab="Likes" key="likes">
            My likes
          </TabPane>
          <TabPane tab="Stats" key="stats">
            My Statistics
              </TabPane> */}
          ֿ
          <TabPane tab="Playground" key="playground">
            <Playground />
          </TabPane>
        </StyledTabs>
      </div>
    </div>
  );
};

export default Profile;
