import axios from 'axios';
import authHeader from './auth-header';
console.log(process.env.REACT_APP_API_BASE);
const API_URL =
  (process.env.REACT_APP_API_BASE === ''
    ? 'http://localhost:3020/'
    : process.env.REACT_APP_API_BASE) + 'users/';

const getById = ({ userId }) => {
  return axios.get(`${API_URL}${userId || ''}`, { headers: authHeader() });
};

const updateUser = (values) => {
  return axios.put(API_URL + 'updateProfile', { ...values.user }, { headers: authHeader() });
};

const updateProfileImage = ({ url }) => {
  return axios.put(
    API_URL + 'updateProfileImage',
    { profileImage: url },
    { headers: authHeader() }
  );
};

const forgotPassword = ({ email }) => {
  return axios.post(API_URL + 'forgotPassword', { email });
};

const changePassword = ({ token, userId, password }) => {
  return axios.post(API_URL + 'changePassword', { userId, token, password });
};

export default {
  updateUser,
  getById,
  updateProfileImage,
  forgotPassword,
  changePassword
};
