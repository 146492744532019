/*global FB*/
import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, Input } from 'antd';
import { CloseCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { isEmail } from 'validator';

import { setRegisterModal, setLoginModal } from 'actions/modals';
import { register, registerWithFBToken } from '../actions/auth';
import { StyledInput } from 'helpers/components';

const RegisterWrap = styled.div``;
const StyledModal = styled(Modal)`
  & .ant-modal-content {
    max-width: 450px;
    min-height: 450px;
    padding: 46px 45px;
    background: #f5f5f5;
    border-radius: 10px;
    @media only screen and (max-width: 768px) {
      padding: 35px 10px;

      min-height: 400px;
    }
  }
  & .ant-modal-body {
    padding: 0;
  }
`;

const Header = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  padding: 10px;
  color: #656565;
`;

const SubHeader = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 10px;
  color: #656565;
`;

const SignUpWithButton = styled(Button)`
  background-color: ${(props) => props.theme.bg};
  height: 40px;
  color: #fff;
  border-radius: 20px;
  border: 0px;
  font-weight: 600;
  align-self: center;
  width: 100%;
  margin-bottom: 20px;

  &.ant-btn:hover {
    background-color: ${(props) => props.theme.bg};
    opacity: 0.8;
    color: #fff;
  }
`;

const TermsDisclaimer = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-bottom: 10px;
  color: #656565;
`;
const LoginLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  display: block;
  color: #656565;
  margin-top: 8px;
`;

const BackArrow = styled.div`
  position: absolute;
  top: 14px;
  left: 20px;
`;

const theme = {
  email: { bg: '#656565' },
  facebook: { bg: '#0031BB' },
  google: { bg: '#D33A3A' }
};

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vname = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const form = useRef();
  const checkBtn = useRef();
  const [page, setPage] = useState('main');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeInviteCode = (e) => {
    const inviteCode = e.target.value;
    setInviteCode(inviteCode);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onSignUpWithEmailClick = () => {
    setPage('loginWithEmail');
  };
  const onBackToMainClick = () => {
    setPage('main');
  };
  const showLoginModal = useCallback(() => {
    dispatch(setLoginModal(true));
  }, [dispatch]);

  const handleRegister = (e) => {
    setSuccessful(false);

    dispatch(register({ firstname, lastname, email, password, inviteCode }))
      .then(() => {
        setSuccessful(true);
      })
      .catch((e) => {
        setSuccessful(false);
      });
  };

  const onClose = () => {
    dispatch(setRegisterModal(false));
  };

  const onFBConnected = (response) => {
    const { authResponse } = response;
    const { accessToken } = authResponse;
    dispatch(registerWithFBToken(accessToken))
      .then(() => {
        props.history.push('/profile');
        window.location.reload();
      })
      .catch(() => {
        setFacebookLoading(false);
      });
  };

  const onFacebookRegister = (e) => {
    setFacebookLoading(true);
    FB.getLoginStatus(function (response) {
      // See the onlogin handler
      console.log(response);
      const { status } = response;
      console.log(response);
      if (status === 'connected') {
        onFBConnected(response);
      } else {
        FB.login(function (response) {
          const { status } = response;
          console.log(response);
          if (status === 'connected') {
            onFBConnected(response);
          }
        });
      }
    });
  };

  if (currentUser) {
    return null;
  }

  return (
    <StyledModal
      closeIcon={<CloseCircleOutlined style={{ fontSize: '22px', color: '#33363F' }} />}
      visible={true}
      onCancel={onClose}
      closable
      footer={false}>
      <RegisterWrap>
        {page === 'main' && (
          <>
            <Header>Sign up to create the hottest product lists</Header>
            <SubHeader>Share with your friends and earn money!</SubHeader>

            <SignUpWithButton onClick={onSignUpWithEmailClick} theme={theme.email}>
              SignUp With Email
            </SignUpWithButton>
            {/* <SignUpWithButton theme={theme.google} onClick={onClose}>
              Continue with google
        </SignUpWithButton> */}
            <SignUpWithButton
              loading={facebookLoading}
              theme={theme.facebook}
              onClick={onFacebookRegister}>
              Continue with facebook
            </SignUpWithButton>
            <TermsDisclaimer>
              By creating an account, you agree to Zelisto&apos;s Terms of Use and Privacy Policy.
            </TermsDisclaimer>
            <LoginLink onClick={showLoginModal}>Already have an account? Login</LoginLink>
          </>
        )}
        {page !== 'main' && (
          <>
            <BackArrow>
              <LeftOutlined onClick={onBackToMainClick} />
            </BackArrow>
            <Header>Sign Up</Header>
            <Form
              name="register_form"
              className="register-form"
              initialValues={{ remember: true }}
              onFinish={handleRegister}
              layout="vertical"
              ref={form}>
              {!successful && (
                <>
                  <Form.Item
                    name="firstname"
                    label="Firstname"
                    rules={[{ required: true, message: 'Please input your firstname' }]}>
                    <StyledInput
                      type="text"
                      name="firstname"
                      value={firstname}
                      onChange={onChangeFirstname}
                      validations={[required, vname]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="lastname"
                    label="Lastname"
                    rules={[{ required: true, message: 'Please input your lastname' }]}>
                    <StyledInput
                      type="text"
                      name="lastname"
                      value={lastname}
                      onChange={onChangeLastname}
                      validations={[required, vname]}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Email is required!' }, { type: 'email' }]}>
                    <StyledInput
                      type="text"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[required, validEmail]}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: 'Please input your password' }]}>
                    <StyledInput
                      type="password"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[required, vpassword]}
                    />
                  </Form.Item>
                  {/*   <Form.Item
                    name="inviteCode"
                    label="Invite Code"
                    rules={[{ required: true, message: 'Please input your invite code' }]}>
                    <StyledInput
                      type="text"
                      name="inviteCode"
                      value={inviteCode}
                      onChange={onChangeInviteCode}
                      validations={[required]}
                    />
              </Form.Item> */}
                  <TermsDisclaimer>
                    By creating an account, you agree to Zelisto&apos;s Terms of Use and Privacy
                    Policy.
                  </TermsDisclaimer>
                  <div className="form-group">
                    <button className="btn btn-primary btn-block">Sign Up</button>
                  </div>
                </>
              )}

              {message && (
                <div className="form-group">
                  <div
                    className={successful ? 'alert alert-success' : 'alert alert-danger'}
                    role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </>
        )}
      </RegisterWrap>
    </StyledModal>
  );
};

export default Register;
