import { Input, Menu, Space } from 'antd';
import styled from 'styled-components';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

const FootWrap = styled.div`
  height: 200px;
  margin-top: 20px;
  width: 1024px;
  margin: 0 auto;
  margin-top: 20px;
`;

export const Footer = ({ context }) => {
  return <FootWrap>Copyright 2023, Zelisto, All Rights Reserved</FootWrap>;
};
