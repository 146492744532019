import React, { useState } from 'react';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import './App.css';

import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import Profile from './components/Profile/Profile';

import ProductsPage from './components/Products/ProductsPage';
import ListPage from './components/Lists/ListPage';
import ListEdit from './components/Lists/ListEdit';
import ProductPage from 'components/Products/ProductPage';
import UserPage from 'components/Profile/UserPage';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import ResetPassword from 'components/ResetPassword';

const Torso = styled.div`
  align-self: center;
  width: 1282px;
  min-height: 60vh;
  border-right: 1px solid #f8e8e8;
  border-left: 1px solid #f8e8e8;
  padding-top: 10px;
  @media only screen and (max-width: 768px) {
    width: 320px;
  }
`;
const WideTorso = styled.div`
  align-self: center;
  width: 100%;
`;
const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

const App = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { registerModal, loginModal } = useSelector((state) => state.modals);

  return (
    <BrowserRouter>
      <PageWrap>
        <Header setSearchTerm={setSearchTerm} />
        {loginModal && <Login />}
        {registerModal && <Register />}

        <Routes>
          <Route
            exact
            path={'/'}
            element={
              <WideTorso>
                <Home />
              </WideTorso>
            }
          />
          <Route
            exact
            path="/reset-password/:userId"
            element={
              <Torso>
                <ResetPassword />
              </Torso>
            }
          />
          <Route
            exact
            path="/profile/:tab"
            element={
              <Torso>
                <Profile />
              </Torso>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Torso>
                <Profile />
              </Torso>
            }
          />
          <Route
            path="/products"
            element={
              <Torso>
                <ProductsPage searchTerm={searchTerm} />
              </Torso>
            }
          />
          <Route
            path="/product/:productId"
            element={
              <Torso>
                <ProductPage />
              </Torso>
            }
          />
          <Route
            path="/lists/:listId"
            element={
              <Torso>
                <ListPage />
              </Torso>
            }
          />
          <Route
            path="/lists/:listId/edit"
            element={
              <Torso>
                <ListEdit />
              </Torso>
            }
          />
          <Route
            path="/users/:userId"
            element={
              <Torso>
                <UserPage />
              </Torso>
            }
          />
        </Routes>

        <Footer />
        {/* <AuthVerify logOut={logOut}/> */}
      </PageWrap>
    </BrowserRouter>
  );
};

export default App;
