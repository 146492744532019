import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Select, Radio, message, Space, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import EventBus from 'common/EventBus';
import ListService from 'services/list.service';
import ListProducts from './ListProducts';
import { StyledInput } from 'helpers/components';
const { Option } = Select;
const { TextArea } = Input;

// eslint-disable-next-line react/display-name
const AddToListTorso = forwardRef((props, ref) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { visible, productId, onClose } = props;
  const [form] = Form.useForm();
  const [userLists, setUserLists] = useState(null);
  const [selectedList, setSelectedList] = useState('create');
  const [confirmLoading, setConfirmLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    onSubmitFromOutside() {
      onSubmit();
    }
  }));

  useEffect(() => {
    if (visible) {
      ListService.getUserLists({ userId: currentUser.id, page: 1, perPage: 20 }).then(
        (response) => {
          setUserLists(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();

          setUserLists(_content);
          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      );
    }
  }, [currentUser, visible]);

  const onSelect = (e) => {
    setSelectedList(e);
  };

  const onSubmit = () => {
    setConfirmLoading(true);
    if (selectedList === 'create') {
      form
        .validateFields()
        .then((values) => {
          ListService.createList({
            name: form.getFieldValue('listname'),
            productId: productId,
            comment: form.getFieldValue('tellUs')
          })
            .then((response) => {
              onClose(false);
              setConfirmLoading(false);
              message.info('List created');
            })
            .catch((error) => {
              setConfirmLoading(false);
            });
        })
        .catch((error) => {
          setConfirmLoading(false);
        });
    } else {
      const listId = selectedList;
      form
        .validateFields()
        .then((values) => {
          ListService.addToList({
            listId,
            productId: productId,
            comment: form.getFieldValue('tellUs')
          })
            .then((response) => {
              onClose(false);
              setConfirmLoading(false);
              message.info('Added an item to the list');
            })
            .catch((error) => {
              setConfirmLoading(false);
            });
        })
        .catch((error) => {
          setConfirmLoading(false);
        });
    }
  };

  return (
    <>
      <Space direction="vertical">
        <Form form={form} layout="vertical" onValuesChange={() => {}}>
          <Form.Item label="" rules={[{ required: true }]}>
            <Select
              placeholder={'Select a list'}
              style={{ width: 450 }}
              onChange={onSelect}
              loading={userLists === null}
              value={selectedList}>
              <Option key={'create'} value={'create'}>
                Create a new list
              </Option>
              {userLists && userLists.map
                ? userLists.map((item, i) => {
                    return (
                      <Option key={item._id} value={item._id}>
                        {item.name}
                      </Option>
                    );
                  })
                : ''}
            </Select>
          </Form.Item>
          <Form.Item
            label="List name:"
            name="listname"
            hidden={selectedList !== 'create'}
            rules={[
              { required: selectedList === 'create', message: 'Please provide a name!', min: 3 }
            ]}>
            <StyledInput />
          </Form.Item>

          <Form.Item
            name={'tellUs'}
            label="Tell us a little about this product:"
            rules={[
              { required: true, message: 'Please add some text about this product ', min: 3 }
            ]}>
            <TextArea
              rows={4}
              minLength={10}
              placeholder="I bought this product a month ago and ever since...."></TextArea>
          </Form.Item>
        </Form>
        {false && <ListProducts listId={selectedList !== 'create' ? selectedList : null} />}
      </Space>
    </>
  );
});

export default AddToListTorso;
