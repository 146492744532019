import React, { createElement, useState } from 'react';
import styled from 'styled-components';
import { Button, Avatar, Tooltip, Form, Input, Row, Col } from 'antd';
const { TextArea } = Input;
import {
  CheckCircleTwoTone,
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined
} from '@ant-design/icons';
import listService from 'services/list.service';

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;
const ProductInfo = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;
`;

const Price = styled.div`
  color: #ff0000;
  display: flex;
  align-self: flex-start;
`;

const Title = styled.div`
  ellipsis: true;
  font-size: 12px;
  height: 80px;
  width: 75%;
  overflow: hidden;
`;
const Image = styled.img`
  object-fit: contain;
  max-height: 120px;
  height: 120px;
  border-radius: 8px;
  box-sizing: border-box;
  justify-content: flex-start;
`;
const StyledComment = styled.div`
  margin-top: 5px;
  border-top: 1px dashed #d9d9d9;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  display: flex;
`;
const BuyNowTag = styled(Button)`
  background-color: #4c2857;
  color: #fff;
  border-radius: 10px;
  border: 0px;
  font-weight: 600;
  align-self: flex-end;
  margin-left: auto;

  &.ant-btn:hover,
  &.ant-btn:focus {
    background-color: #4c2857;
    color: #fff;
    opacity: 0.9;
  }
`;

const MainWrap = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 10px;
  width: 500px;
  padding: 10px 5px;
`;

const CommentWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  margin-top: 8px;
`;

const Comment = styled.div`
  width: 65%;
  padding-right: 10px;
  align-self: flex-start;
`;
const ProductForSideListView = ({ listId, item, editMode }) => {
  const [form] = Form.useForm();
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  const like = () => {
    setLikes(1);
    setDislikes(0);
    setAction('liked');
  };

  const dislike = () => {
    setLikes(0);
    setDislikes(1);
    setAction('disliked');
  };
  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{likes}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(action === 'disliked' ? DislikeFilled : DislikeOutlined)}
        <span className="comment-action">{dislikes}</span>
      </span>
    </Tooltip>
  ];
  const onSubmitClick = (e) => {
    listService
      .updateProductComment({
        listId,
        productId: item._id,
        comment: form.getFieldValue('comment')
      })
      .then((response) => {
        setIsDirty(false);
      })
      .catch((error) => {});
  };
  const onChange = (e) => {
    setIsDirty(true);
  };
  return (
    <>
      <MainWrap>
        <Card>
          <Image alt={item.title} height={240} src={item.thumbailUrl} />
          <ProductInfo>
            <Title description="">{item.title}</Title>
            <Price>
              {item.currency}
              {item.price}
            </Price>
          </ProductInfo>
        </Card>
        <StyledComment>
          {editMode ? (
            <Form form={form} name={`product_${item._id}_comment`}>
              <Input.Group compact>
                <Form.Item
                  name="comment"
                  label="Comment"
                  initialValue={item.comment}
                  onChange={onChange}
                  rules={[{ required: true }]}>
                  <TextArea rows={4} style={{ width: '100%' }} textarea value={item.comment} />
                </Form.Item>
                <Button
                  onClick={onSubmitClick}
                  shape="circle"
                  icon={<CheckCircleTwoTone twoToneColor={!isDirty && '#cfcfcf'} />}
                />
              </Input.Group>
            </Form>
          ) : (
            <CommentWrap>
              <Comment>{item.comment}</Comment>
              <BuyNowTag>
                <a href={`/product/${item._id}`}>Buy Now</a>
              </BuyNowTag>
            </CommentWrap>
          )}
        </StyledComment>
      </MainWrap>
    </>
  );
};

export default ProductForSideListView;
