import React, { useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StyledPasswordInput } from 'helpers/components';
import userService from 'services/user.service';
import { setLoginModal } from 'actions/modals';

const FormWrap = styled.div`
  max-width: 500px;
  padding: 10px 10px;
`;

const Header = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  padding: 10px;
  color: #656565;
`;

const LoginButton = styled(Button)`
  height: 40px;
  color: #fff;
  border-radius: 20px;
  border: 0px;
  font-weight: 600;
  align-self: center;
  width: 100%;
`;

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotForm] = Form.useForm();
  const { userId } = useParams();
  const [forgotLoading, setForgotLoading] = useState(false);
  const [forgotMessage, setForgotMessage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const QS = useQuery();
  const token = QS.get('token');
  const { user: currentUser } = useSelector((state) => state.auth);
  const showLoginModal = useCallback(() => {
    dispatch(setLoginModal(true));
  }, [dispatch]);

  if (currentUser) {
    navigate('/');
    return;
  }

  const handleReset = (values) => {
    setForgotLoading(true);
    userService
      .changePassword({ userId, token, password: forgotForm.getFieldValue('password') })
      .then((response) => {
        setForgotLoading(false);
        setButtonDisabled(true);
        showLoginModal(true);
        navigate('/');
        setForgotMessage({ successful: true, message: 'Password changed successfully' });
      })
      .catch((error) => {
        setForgotLoading(false);
        setForgotMessage({ successful: false, message: 'We encountered an error, try again.' });
      });
  };
  const checkDoublePass = (rule, value) => {
    if (value && value !== forgotForm.getFieldValue('password')) {
      return Promise.reject('Passwords do not match!');
    }
    return Promise.resolve();
  };
  return (
    <>
      <FormWrap>
        <Header>Forgot you password? No worrries!</Header>
        <Form
          name="forgotForm"
          className="forgot-form"
          onFinish={handleReset}
          layout="vertical"
          form={forgotForm}>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: 'Please input your new password' },
              { min: 6, message: 'Password must be at least 6 characters' }
            ]}>
            <StyledPasswordInput placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="password2"
            label="Retype password"
            rules={[
              { required: true, message: 'Please input your password again' },
              {
                validator: checkDoublePass,
                message: 'Passwords do not match!'
              },
              { min: 6, message: 'Password must be at least 6 characters' }
            ]}>
            <StyledPasswordInput placeholder="Password2" />
          </Form.Item>
          <Form.Item>
            <LoginButton
              type="primary"
              htmlType="submit"
              loading={forgotLoading}
              disabled={buttonDisabled}
              className="login-form-button">
              Change password
            </LoginButton>
          </Form.Item>

          {forgotMessage && (
            <div className="form-group">
              <div
                className={forgotMessage.successful ? 'alert alert-success' : 'alert alert-danger'}
                role="alert">
                {forgotMessage.message}
              </div>
            </div>
          )}
        </Form>
      </FormWrap>
    </>
  );
};

export default ResetPassword;
