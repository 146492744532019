import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { message, Button, Form, Input, Spin } from 'antd';
const { TextArea } = Input;
import { useSelector } from 'react-redux';
import ListService from 'services/list.service';
import ListProducts from './ListProducts';
import ListImageUpdate from './ListImageUpdate';
import listService from 'services/list.service';
import { StyledInput } from 'helpers/components';

const UpdateButton = styled(Button)`
  background: #656565;
  height: 40px;
  color: #fff;
  border-radius: 20px;
  border: 0px;
  font-weight: 600;
  align-self: flex-end;
  margin-left: auto;
  width: 310px;
  margin-bottom: 20px;

  &.ant-btn:hover {
    background: #656565;
    opacity: 0.8;
    color: #fff;
  }
`;

const AvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
`;

const ListEdit = () => {
  const [form] = Form.useForm();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { listId } = useParams();
  const [list, setList] = useState(null);
  useEffect(() => {
    ListService.getListById({ listId }).then(
      (response) => {
        setList(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        setList(_content);
      }
    );
  }, []);
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  };
  const onFinish = (values) => {
    const { list } = values;
    listService
      .updateList({ listId, list })
      .then((response) => {
        message.info('List updated successfully');
      })
      .catch((error) => {
        message.error('Error updating list');
      });
  };
  return (
    <div className="container">
      {list ? (
        <>
          <AvatarWrap>
            <ListImageUpdate list={list} />
          </AvatarWrap>
          <Form ref={form} name="list-edit" onFinish={onFinish} validateMessages={validateMessages}>
            <Form.Item
              name={['list', 'name']}
              label="Title"
              initialValue={list.name}
              rules={[{ required: true }]}>
              <StyledInput />
            </Form.Item>
            <Form.Item
              name={['list', 'description']}
              label="Description"
              initialValue={list.description}
              rules={[{ required: true }]}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <UpdateButton htmlType="submit" type="primary">
                Submit
              </UpdateButton>
            </Form.Item>
          </Form>
          <ListProducts editMode={true} listId={listId} />
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default ListEdit;
