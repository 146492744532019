const isDevEnv = process.env.NODE_ENV !== 'production';
const SUPPORTED_TRANSFORMATIONS = [
  'gravity',
  'opacity',
  'x',
  'y',
  'width',
  'height',
  'quality',
  'fetch_format',
  'overlay',
  'crop'
];

const BUCKET_NAME = 'zelisto-com';

const ROOT_URL = `https://res.cloudinary.com/${BUCKET_NAME}`;

// eslint-disable-next-line class-methods-use-this
const _processTransformations = (transformation) => {
  let transformationUrl = '/';
  const mappedTransforms = [];

  if (transformation.gravity) mappedTransforms.push(`g_${transformation.gravity}`);
  if (transformation.opacity) mappedTransforms.push(`o_${transformation.opacity}`);
  if (transformation.x) mappedTransforms.push(`x_${transformation.x}`);
  if (transformation.y) mappedTransforms.push(`y_${transformation.y}`);
  if (transformation.width) mappedTransforms.push(`w_${transformation.width}`);
  if (transformation.height) mappedTransforms.push(`h_${transformation.height}`);
  if (transformation.quality) mappedTransforms.push(`q_${transformation.quality}`);
  if (transformation.fetch_format) mappedTransforms.push(`f_${transformation.fetch_format}`);
  if (transformation.crop) mappedTransforms.push(`c_${transformation.crop}`);

  transformationUrl += mappedTransforms.join(',');

  return transformationUrl;
};

export const cloudinaryUrl = (imageUrl, options) => {
  if (!imageUrl) {
    return imageUrl;
  }
  if (imageUrl.indexOf('zelisto') === -1) {
    return imageUrl;
  }
  let newUrl = imageUrl;

  if (options.type === 'fetch' && options.transformation) {
    newUrl = `${ROOT_URL}/image/fetch`;
    newUrl += _processTransformations(options.transformation);
    newUrl += `/${encodeURIComponent(imageUrl)}`;
  }

  return newUrl;
};
