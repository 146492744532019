import produce from 'immer';
import { uploadStateTypes } from 'helpers/enums';
import {
  UPLOAD_START,
  UPLOAD_PROGRESS_UPDATE,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE,
  UPLOAD_CANCEL_SIGNAL,
  UPLOAD_CANCEL
} from '../actions/upload';

const initialState = {};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { uploadId } = action.payload || {};

    if (uploadId && !state[uploadId]) {
      draft[uploadId] = {};
    }

    switch (action.type) {
      case UPLOAD_START:
        draft[action.payload.uploadId].state = uploadStateTypes.UPLOADING;
        draft[action.payload.uploadId].progress = 0;
        break;

      case UPLOAD_PROGRESS_UPDATE:
        draft[action.payload.uploadId].progress = action.payload.value;
        break;

      case UPLOAD_SUCCESS:
        draft[action.payload.uploadId].state = uploadStateTypes.SUCCEED;
        draft[action.payload.uploadId].progress = 1;
        break;

      case UPLOAD_CANCEL_SIGNAL:
        draft[action.payload.uploadId].state = uploadStateTypes.CANCEL_SIGNALED;
        break;

      case UPLOAD_CANCEL:
        draft[action.payload.uploadId].state = uploadStateTypes.CANCELLED;
        break;

      case UPLOAD_FAILURE:
        draft[action.payload.uploadId].state = uploadStateTypes.FAILED;
        break;

      default:
    }
  });

export default reducer;
