import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import ProductService from 'services/product.service';
import EventBus from 'common/EventBus';
import ProductForSideListView from './ProductForSideListView';
import { Form } from 'antd';
const ProductsWrap = styled.div`
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  align-content: space-between;
`;
const Header = styled.h3`
  font-size: 1.5rem;
`;

const ListProducts = ({ listId, editMode }) => {
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(9);

  useEffect(() => {
    if (listId) {
      ProductService.getProductsByListId({ listId, page: currentPage, perPage }).then(
        (response) => {
          setTotalCount(response.headers['x-total-count']);
          setProducts(response.data.data);
        },
        (error) => {
          setTotalCount(0);
          setProducts([]);
          const _content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          console.error(_content);
          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      );
    } else {
      setTotalCount(0);
      setProducts([]);
    }
  }, [listId, currentPage, perPage]);

  return (
    <div className="container">
      {products && products.map && products.length ? (
        <ProductsWrap>
          {products.map((item, i) => {
            // Return the element. Also pass key
            return (
              <ProductForSideListView
                listId={listId}
                editMode={editMode}
                key={item._id}
                item={item}
              />
            );
          })}
        </ProductsWrap>
      ) : (
        ''
      )}
    </div>
  );
};

export default ListProducts;
