import axios from 'axios';
import authHeader from './auth-header';

const API_URL =
  (process.env.REACT_APP_API_BASE === ''
    ? 'http://localhost:3020/'
    : process.env.REACT_APP_API_BASE) + 'uploads/';

const getUploadToken = async ({ entityType, filename }) => {
  return axios
    .post(
      API_URL + 'gettoken',
      { entityType, filename },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getUploadToken
};
