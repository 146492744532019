import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Pagination, Skeleton, Space } from 'antd';
import EventBus from 'common/EventBus';
import Product from './Product';
import ProductService from 'services/product.service';

const ProductsWrap = styled.div`
  border-radius: 8px;
  box-sizing: border-box;

  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-content: center;
`;
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const Title = styled.h1``;

const Products = ({ excludeProduct, category, searchTerm, title, numOfItems, noPagination }) => {
  const [products, setProducts] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(numOfItems || 12);
  const onPaginationChange = (page, pageSize) => {
    setProducts([]);
    setCurrentPage(page);
    setPerPage(pageSize);
  };

  useEffect(() => {
    if (category || searchTerm) {
      ProductService.getProducts({
        excludeProduct,
        category,
        searchTerm,
        page: currentPage,
        perPage
      }).then(
        (response) => {
          setTotalCount(response.headers['x-total-count']);
          setProducts(response.data);
        },
        (error) => {
          setProducts([]);
          /*  if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          } */
        }
      );
    }
  }, [category, searchTerm, currentPage, perPage]);
  return (
    <>
      {title ? <Title>{title}</Title> : ''}

      <ProductsWrap>
        {products && products.map
          ? products.map((item, i) => {
              // Return the element. Also pass key
              return <Product key={`${i}_${item.ExternalId}`} item={item} />;
            })
          : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, i) => {
              return <Skeleton active key={`skelaton_${i}`} />;
            })}
      </ProductsWrap>
      <PaginationWrap>
        {!noPagination && (
          <Pagination
            current={currentPage}
            onChange={onPaginationChange}
            pageSize={perPage}
            total={totalCount}
          />
        )}
      </PaginationWrap>
    </>
  );
};

export default Products;
