import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Space, Modal, Form, Input, message } from 'antd';
import { debounceEventHandler } from 'helpers/events';
import { PlusCircleOutlined } from '@ant-design/icons';

import scrapedUrlService from 'services/scrapedUrl.service';
import productService from 'services/product.service';
import ProductPreview from './ProductPreview';
import AddToListTorso from 'components/Lists/AddToAListTorso';
const { Search } = Input;

const FooterElem = styled.div`
  display: flex;
`;

const UrlItemPreview = styled.div`
  display: flex;
  justify-content: right;
  align-self: right;
`;

const CreateProductButton = () => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [createdProduct, setCreatedProduct] = useState('');
  const addToListRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [previewUrlData, setPreviewUrlData] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [previewSpinner, setPreviewSpinner] = useState(false);

  useEffect(() => {
    if (createdProduct) {
      addToListRef.current.onSubmitFromOutside();
    }
  }, [createdProduct]);

  const handleFormChange = (e) => {
    form
      .validateFields(['url'])
      .then((values) => {
        const url = form.getFieldValue('url');
        setPreviewSpinner(true);
        scrapedUrlService
          .getScrapedUrlPreview(url)
          .then((data) => {
            setPreviewUrlData(data.data);
            setPreviewSpinner(false);
          })
          .catch((err) => {
            console.log(err);
            setPreviewSpinner(false);
          });
      })
      .catch((err) => {});
  };

  const onClose = () => {
    setConfirmLoading(true);
    setCurrent(0);
    setCreatedProduct(null);
    setVisible(false);
    setConfirmLoading(false);
    setPreviewUrlData(null);
    form.resetFields();
  };
  const onSubmit = () => {
    setConfirmLoading(true);
    const url = form.getFieldValue('url');
    productService
      .create({ url })
      .then(async (data) => {
        setCreatedProduct(data.data);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setConfirmLoading(false);
      });
  };
  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        <PlusCircleOutlined /> Add a product
      </Button>
      <Modal
        destroyOnClose={true}
        title={current === 0 ? 'Add a new product' : 'Add to a list'}
        centered
        visible={visible}
        onClose={onClose}
        closable={false}
        confirmLoading={confirmLoading}
        width={600}
        footer={
          <FooterElem>
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="primary" onClick={onSubmit}>
                OK
              </Button>
            </Space>
          </FooterElem>
        }>
        {current === 0 ? (
          <>
            <Form
              key={visible}
              onChange={debounceEventHandler(handleFormChange, 500)}
              form={form}
              layout="vertical">
              <Form.Item
                label="Url"
                name="url"
                rules={[
                  { required: true, message: 'Please provide a url!' },
                  {
                    pattern:
                      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                    message: 'Please provide a valid url!'
                  }
                ]}>
                <Search loading={previewSpinner} />
              </Form.Item>
              <UrlItemPreview>
                {previewUrlData ? <ProductPreview item={previewUrlData.data} /> : null}
              </UrlItemPreview>
            </Form>

            <AddToListTorso
              ref={addToListRef}
              productId={createdProduct && createdProduct._id}
              visible={visible}
              onClose={onClose}
            />
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default CreateProductButton;
