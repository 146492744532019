import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import ProductService from '../../services/product.service';
import EventBus from '../../common/EventBus';

const { Option } = Select;
const Categories = ({ onSelect, searchTerm }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    onSelect('featured');
    ProductService.getCategories().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  return (
    <>
      <Select
        placeholder={searchTerm ? 'All' : ''}
        disabled={!!searchTerm}
        defaultValue="featured"
        style={{ width: 450 }}
        onChange={onSelect}>
        <Option key={'featured'} value={'featured'}>
          Featured
        </Option>
        {content && content.map
          ? content.map((item, i) => {
              return (
                <Option key={item._id} value={item._id}>
                  {item._id}
                </Option>
              );
            })
          : ''}
      </Select>
    </>
  );
};

export default Categories;
