import React, { useState, useEffect } from 'react';
import { Pagination, Skeleton, Space } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import ProductService from 'services/product.service';

import Products from './Products';
import Categories from './Categories';
import CreateProductButton from './CreateProductButton';

const TopOfListContainer = styled.div`
  width: 100%;

  flex: 1;
  padding: 10px;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;

const CenterMe = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCreateProductButton = styled(CreateProductButton)`
  display: flex;
`;

const ProductsPage = ({ searchTerm }) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [category, setCategory] = useState('');

  const onCategorySelect = (category) => {
    setCategory(category);
    // setCurrentPage(1);
  };

  if (!currentUser) {
    return;
  }
  return (
    <div className="">
      <TopOfListContainer>
        <Categories onSelect={onCategorySelect} searchTerm={searchTerm} />
        <StyledCreateProductButton />
      </TopOfListContainer>

      <Products category={category} searchTerm={searchTerm}></Products>
    </div>
  );
};

export default ProductsPage;
