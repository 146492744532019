import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Avatar, Spin } from 'antd';
import { CameraFilled } from '@ant-design/icons';
import { getRotatedFile, getUploadId } from 'helpers/upload';
import { upload } from 'actions/upload';
import { refreshToken } from 'actions/auth';
import userService from 'services/user.service';

const StyledAvatar = styled(Avatar)`
  &.ant-avatar {
    overflow: auto !important;
    position: relative;
  }
`;

const Wrap = styled.div`
  position: relative;
`;

const AvatarCamera = styled.a`
  .anticon {
    position: absolute;
    padding: 8px;
    border: 1px solid #e6e6e6;
    border-radius: 20px;
    background: #f5f5f5;
    color: #4d4d4d;
    bottom: 0px;
    right: 0px;
  }
`;

const ProfileAvatar = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [image, setImage] = useState(currentUser?.profileImage);
  const dispatch = useDispatch();
  const handleDrop = async (dropped) => {
    const file = dropped[0];

    const rotatedFile = await getRotatedFile(file);
    setImage(rotatedFile);

    const currentUploadId = getUploadId(file);

    dispatch(
      upload({
        entityType: 'PROFILE',
        uploadId: currentUploadId,
        file,
        filename: file.name,
        onError: onUploadError,
        onFinish: onUploadFinish
      })
    );
  };
  const onUploadError = (e) => {
    console.error(e);
    setImage(null);
  };

  const onUploadFinish = async (e) => {
    console.log(e);
    setImage(e.url);
    await userService.updateProfileImage({ url: e.url });
    dispatch(refreshToken());
  };

  const onUploadInitialized = (e) => {
    e.preventDefault();
    open(e);
  };

  const { open } = useDropzone({ onDrop: handleDrop });
  return (
    <>
      <Wrap>
        <StyledAvatar
          size={128}
          src={image || currentUser?.profileImage}
          style={{
            textTransform: 'uppercase',
            color: '#f56a00',
            backgroundColor: '#fde3cf'
          }}>
          {image ? '' : currentUser?.firstname}
        </StyledAvatar>
        <AvatarCamera onClick={onUploadInitialized}>
          <CameraFilled style={{}} />
        </AvatarCamera>
      </Wrap>
    </>
  );
};

export default ProfileAvatar;
