import React from 'react';
import styled from 'styled-components';
import { Popconfirm } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import listService from 'services/list.service';
import { cloudinaryUrl } from 'helpers/cloudinary';

const ListContainer = styled.div`
  width: 280px;

  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  position: relative;
`;

const StyledCard = styled.div`
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background: #ededed;
  font-size: 14px;
`;

const ListName = styled.a`
  display: block;
  font-size: 16px;
  color: #212529;

  :hover {
    color: #212529;
  }
`;

const RemoveList = styled(Popconfirm)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const ListImages = styled.a`
  display: block;
  margin-bottom: 15px;
  margin-top: 10px;
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
`;

const ListImage = styled.img`
  object-fit: contain;
  max-height: 235px;
  height: 235px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const List = ({ item, onListRemoved }) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  const onConfirm = async () => {
    await listService.deleteList(item._id);
    onListRemoved(item._id);
  };
  let listImage = '';
  if (item) {
    if (item.mainImage) {
      listImage = item.mainImage;
    } else {
      listImage = item?.products[0]?.image;
    }
  }
  listImage = cloudinaryUrl(listImage, {
    type: 'fetch',
    transformation: {
      quality: 'auto',
      fetch_format: 'webp',
      height: '235'
    }
  });
  return (
    <StyledCard>
      <ListContainer>
        <ListName href={`/lists/${item._id}`}>{item.name}</ListName>
        <ListName href={`/lists/${item._id}`}>
          by {item.user ? item.user[0].firstname : ''} {item.user ? item.user[0].lastname : ''}
        </ListName>
        <ListImages href={`/lists/${item._id}`}>
          <ListImage src={listImage} />
        </ListImages>
        <div>({item.products?.length || 0} items)</div>
        {item && item?.userId._id === currentUser?.id ? (
          <RemoveList title="Are you sure？" okText="Yes" cancelText="No" onConfirm={onConfirm}>
            <CloseCircleOutlined />
          </RemoveList>
        ) : (
          ''
        )}
      </ListContainer>
    </StyledCard>
  );
};

export default List;
