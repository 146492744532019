import axios from 'axios';
import authHeader from './auth-header';

const API_URL =
  (process.env.REACT_APP_API_BASE === ''
    ? 'http://localhost:3020/'
    : process.env.REACT_APP_API_BASE) + 'products/';

const getById = ({ productId }) => {
  return axios.get(`${API_URL}${productId}`, { headers: authHeader() });
};
const getProducts = ({ excludeProduct, category, searchTerm, page, perPage }) => {
  return axios.get(
    `${API_URL}?category=${encodeURIComponent(
      category
    )}&q=${searchTerm}&page=${page}&perPage=${perPage}&exclude=${excludeProduct || ''}`,
    {
      headers: authHeader()
    }
  );
};

const getProductsByListId = ({ listId, page, perPage }) => {
  return axios.get(`${API_URL}byList?listId=${listId}&page=${page}&perPage=${perPage}`, {
    headers: authHeader()
  });
};

const getCategories = () => {
  return axios.get(API_URL + 'categories', { headers: authHeader() });
};

const create = async ({ url }) => {
  return axios
    .post(
      API_URL + '',
      {
        url
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getById,
  getProducts,
  getCategories,
  getProductsByListId,
  create
};
