import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import modals from './modals';
import upload from './upload';

export default combineReducers({
  auth,
  message,
  modals,
  upload
});
