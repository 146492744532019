import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Carousel } from 'antd';
import FeaturedLists from './Lists/FeaturedLists';
import Products from './Products/Products';
import { cloudinaryUrl } from 'helpers/cloudinary';

const Torso = styled.div`
  align-self: center;
  width: 1282px;
  min-height: 60vh;
  border-right: 1px solid #f8e8e8;
  border-left: 1px solid #f8e8e8;
  @media only screen and (max-width: 768px) {
    width: 320px;
  }
`;
const HomeWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const CarouselItem = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const CarouselGradient = styled.div`
  position: absolute;
  width: 60%;
  height: 680px;
  left: 0px;
  top: 0px;
  background: linear-gradient(90deg, #4c2857 0%, rgba(217, 217, 217, 0) 100%);
  @media only screen and (max-width: 768px) {
    height: 340px;
    width: 80%;
  }
`;

const CarouselImage = styled.img`
  object-fit: cover;
  object-position: left top;
  width: 100%;
  height: 680px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    height: 340px;
  }
`;

const CarouselText = styled.p`
  color: #ffffff;
  position: absolute;
  top: 10%;
  left: 5%;
  width: 30%;
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 72px;

  @media only screen and (max-width: 768px) {
    width: 60%;
    font-size: 30px;
    line-height: 35px;
  }
`;

const CenterMe = styled.div`
  display: flex;
  justify-content: center;
`;
const Home = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    // return;
  }
  return (
    <HomeWrap>
      <Carousel autoplay>
        <div>
          <CarouselItem>
            <CarouselGradient />
            <CarouselText>
              Shop like others Save money, time and create your own listing
            </CarouselText>
            <CarouselImage
              src={cloudinaryUrl('https://zelisto.com/assets/shutterstock_1890205177.jpg', {
                type: 'fetch',
                transformation: {
                  quality: 'auto',
                  fetch_format: 'webp',
                  width: '1280'
                }
              })}></CarouselImage>
          </CarouselItem>
        </div>
      </Carousel>
      <Torso>
        <FeaturedLists
          numOfItems={4}
          noPagination
          title={'Featured curated lists:'}></FeaturedLists>
        <h1>Coffee:</h1>
        <CenterMe>
          <Products
            title=""
            category={''}
            searchTerm={'coffee'}
            noPagination
            numOfItems={4}></Products>
        </CenterMe>
        <h1>Shoes:</h1>
        <CenterMe>
          <Products
            title=""
            category={''}
            searchTerm={'shoes'}
            noPagination
            numOfItems={4}></Products>
        </CenterMe>
        <h1>Cables:</h1>
        <CenterMe>
          <Products
            title=""
            category={''}
            searchTerm={'cables'}
            noPagination
            numOfItems={4}></Products>
        </CenterMe>
        <h1>Chargers:</h1>
        <CenterMe>
          <Products
            title=""
            category={''}
            searchTerm={'chargers'}
            noPagination
            numOfItems={4}></Products>
        </CenterMe>
      </Torso>
    </HomeWrap>
  );
};

export default Home;
