import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Drawer } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import AddToAListTorso from './AddToAListTorso';
const AddToList = styled(Button)`
  background-color: #ebb813;
  color: #fff;
  border-radius: 10px;
  border: 0px;
  font-weight: 600;
  align-self: flex-end;
  margin-left: auto;

  &.ant-btn:hover,
  &.ant-btn:focus {
    background-color: #ebb813;
    color: #fff;
    opacity: 0.8;
  }
`;
const AddToListButton = ({ productId }) => {
  const [visible, setVisible] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const addToListRef = useRef(null);

  const onClose = () => {
    setVisible(false);
  };
  const onSubmit = () => {
    // eslint-disable-next-line no-debugger
    addToListRef.current.onSubmitFromOutside();
  };
  return (
    <>
      <AddToList type="primary" disabled={!currentUser} onClick={() => setVisible(true)}>
        Add to list
      </AddToList>
      <Modal
        title="Add to a list"
        placement={'right'}
        width={500}
        onClose={onClose}
        visible={visible}
        closable={false}
        footer={
          <div>
            <div>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="primary" onClick={onSubmit}>
                OK
              </Button>
            </div>
          </div>
        }>
        <AddToAListTorso
          ref={addToListRef}
          visible={visible}
          productId={productId}
          onClose={onClose}></AddToAListTorso>
      </Modal>
    </>
  );
};

export default AddToListButton;
