import axios from 'axios';

import { fetchTypes, uploadStateTypes } from 'helpers/enums';
import uniqueId from 'lodash/uniqueId';
import uploadService from 'services/upload.service';

export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_PROGRESS_UPDATE = 'UPLOAD_PROGRESS_UPDATE';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
export const UPLOAD_CANCEL_SIGNAL = 'UPLOAD_CANCEL_SIGNAL';
export const UPLOAD_CANCEL = 'UPLOAD_CANCEL';

const { CancelToken } = axios;
const sources = {};

function throwExceptionIfCancelSignaled(state, uploadId, msg) {
  if (state.upload[uploadId] === fetchTypes.CANCEL_SIGNALED) {
    throw new Error(msg);
  }
}

export const updateProgress = ({ uploadId, value }) => ({
  type: UPLOAD_PROGRESS_UPDATE,
  payload: { uploadId, value }
});

// eslint-disable-next-line consistent-return
export const upload =
  ({ entityType, filename, canvas, file, onError, onStart, onFinish }) =>
  async (dispatch, getState) => {
    const uploadId = uniqueId();
    dispatch({ type: UPLOAD_START, payload: { uploadId } });

    if (onStart) {
      onStart(uploadId);
    }

    let url;
    try {
      const res = await uploadService.getUploadToken({ entityType, filename });

      throwExceptionIfCancelSignaled(getState(), uploadId, 'pre-fetch-cancel');

      const { params, uploadEndpoint } = res.data;
      const formData = new FormData();
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      if (canvas) {
        await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            formData.append('file', blob, filename);
            resolve();
          });
        });
      } else {
        formData.append('file', file, filename);
      }

      const instance = axios.create({
        baseURL: uploadEndpoint,
        timeout: 999999,
        onUploadProgress: (progress) =>
          dispatch(updateProgress({ uploadId, value: progress.loaded / progress.total }))
      });

      sources[uploadId] = CancelToken.source();

      await instance.post(uploadEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        cancelToken: sources[uploadId].token
      });

      dispatch({ type: UPLOAD_SUCCESS, payload: { uploadId } });

      // not extracting the file url from the response to avoid XML parsing (JSON is not supported by S3).
      // using the signature data supposed to be valid in the same manner.
      url = `${uploadEndpoint}/${params.key}`;
      return { url };
    } catch (err) {
      console.error(err);
      if (
        err.message === 'cancelled' ||
        err.message === 'Canceled' ||
        err.message === 'pre-fetch-cancel'
      ) {
        // 'cancelled' is thrown at iOS by RNFetchBlob when cancelled during fetch, and 'Canceled' at Android. 'pre-fetch-cancel' is thrown between phases.
        dispatch({ type: UPLOAD_CANCEL, payload: { uploadId } });
        return { err: uploadStateTypes.CANCELLED };
      } else {
        dispatch({ type: UPLOAD_FAILURE, payload: { uploadId } });
        if (onError) {
          onError(err);
        }
        return { err: uploadStateTypes.FAILED };
      }
    } finally {
      if (onFinish) {
        onFinish({ url });
      }
    }
  };

export const cancelUpload =
  ({ uploadId }) =>
  (dispatch) => {
    dispatch({ type: UPLOAD_CANCEL_SIGNAL, payload: { uploadId } });
    sources[uploadId].cancel('Operation canceled by the user.');
  };
