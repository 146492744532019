import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Avatar, Spin } from 'antd';
import { CameraFilled } from '@ant-design/icons';
import { getRotatedFile, getUploadId } from 'helpers/upload';
import { upload } from 'actions/upload';
import listService from 'services/list.service';

const Wrap = styled.div`
  position: relative;
`;

const AvatarCamera = styled.a`
  .anticon {
    position: absolute;
    padding: 8px;
    border: 1px solid #e6e6e6;
    border-radius: 20px;
    background: #f5f5f5;
    color: #4d4d4d;
    bottom: 0px;
    right: 0px;
  }
`;

const ListImageUpdate = ({ list }) => {
  const [image, setImage] = useState(list?.mainImage);
  const dispatch = useDispatch();
  const handleDrop = async (dropped) => {
    const file = dropped[0];

    const rotatedFile = await getRotatedFile(file);
    setImage(rotatedFile);

    const currentUploadId = getUploadId(file);

    dispatch(
      upload({
        entityType: 'LIST',
        uploadId: currentUploadId,
        file,
        filename: file.name,
        onError: onUploadError,
        onFinish: onUploadFinish
      })
    );
  };
  const onUploadError = (e) => {
    console.error(e);
    setImage(null);
  };

  const onUploadFinish = async (e) => {
    setImage(e.url);
    await listService.updateListMainImage({ listId: list._id, url: e.url });
  };

  const onUploadInitialized = (e) => {
    e.preventDefault();
    open(e);
  };

  const { open } = useDropzone({ onDrop: handleDrop });
  return (
    <>
      <Wrap>
        <Avatar
          size={128}
          src={image}
          style={{
            color: '#f56a00',
            backgroundColor: '#fde3cf'
          }}></Avatar>

        <AvatarCamera onClick={onUploadInitialized}>
          <CameraFilled style={{}} />
        </AvatarCamera>
      </Wrap>
    </>
  );
};

export default ListImageUpdate;
