/*global FB*/
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { LeftOutlined, LockOutlined, UserOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Modal } from 'antd';
import { StyledInput } from 'helpers/components';
import { login, loginWithFBToken } from '../actions/auth';
import { setLoginModal } from 'actions/modals';
import userService from 'services/user.service';

const LoginWrap = styled.div`
  max-width: 500px;
  padding: 10px 10px;
`;
const StyledModal = styled(Modal)`
  & .ant-modal-content {
    max-width: 450px;
    min-height: 450px;
    padding: 46px 45px;
    background: #f5f5f5;
    border-radius: 10px;
    @media only screen and (max-width: 768px) {
      padding: 35px 10px;

      min-height: 400px;
    }
  }
  & .ant-modal-body {
    padding: 0;
  }
`;

const SignUpWithButton = styled(Button)`
  background-color: ${(props) => props.theme.bg};
  height: 40px;
  color: #fff;
  border-radius: 20px;
  border: 0px;
  font-weight: 600;
  align-self: center;
  width: 100%;

  &.ant-btn:hover {
    background-color: ${(props) => props.theme.bg};
    opacity: 0.8;
    color: #fff;
  }
`;

const LoginButton = styled(Button)`
  height: 40px;
  color: #fff;
  border-radius: 20px;
  border: 0px;
  font-weight: 600;
  align-self: center;
  width: 100%;
`;
const Header = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  padding: 10px;
  color: #656565;
`;
const theme = {
  email: { bg: '#656565' },
  facebook: { bg: '#0031BB' },
  google: { bg: '#D33A3A' }
};

const BackArrow = styled.div`
  position: absolute;
  top: 14px;
  left: 20px;
`;

const Login = (props) => {
  const [form] = Form.useForm();
  const [forgotForm] = Form.useForm();
  const [page, setPage] = useState('main');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [forgotLoading, setForgotLoading] = useState(false);
  const [forgotMessage, setForgotMessage] = useState(null);
  const [forgotDisabled, setForgotDisabled] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    setLoading(true);

    dispatch(login(username, password))
      .then(() => {
        dispatch(setLoginModal(false));
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onClose = () => {
    dispatch(setLoginModal(false));
  };
  const onFBConnected = (response) => {
    const { authResponse } = response;
    const { accessToken } = authResponse;
    dispatch(loginWithFBToken(accessToken))
      .then(() => {
        dispatch(setLoginModal(false));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleFBLogin = (e) => {
    setLoading(true);
    FB.getLoginStatus(function (response) {
      // See the onlogin handler
      console.log(response);
      const { status } = response;
      console.log(response);
      if (status === 'connected') {
        onFBConnected(response);
      } else {
        FB.login(function (response) {
          const { status } = response;
          console.log(response);
          if (status === 'connected') {
            onFBConnected(response);
          }
        });
      }
    });
  };

  const moveToForgotPage = () => {
    const currentEmail = form.getFieldValue('email');
    forgotForm.setFieldsValue({ email: currentEmail });
    setForgotDisabled(false);
    setPage('forgot');
  };

  const onBackToLogin = () => {
    setPage('main');
  };

  const handleForgot = (values) => {
    setForgotLoading(true);

    userService
      .forgotPassword({ email: values.email })
      .then((response) => {
        setForgotLoading(false);
        setForgotDisabled(true);
        setForgotMessage({
          successful: true,
          message: 'An email with reset password link has been sent to your email address.'
        });
      })
      .catch((error) => {
        setForgotLoading(false);

        setForgotMessage({
          successful: false,
          message: 'Something went wrong. Please try again.'
        });
      });
  };
  return (
    <StyledModal
      closeIcon={<CloseCircleOutlined style={{ fontSize: '22px', color: '#33363F' }} />}
      visible={true}
      onCancel={onClose}
      closable
      footer={false}>
      <LoginWrap className="">
        {page === 'main' && (
          <>
            <Header>Welcome back!</Header>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={handleLogin}
              layout="vertical"
              form={form}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email' }
                ]}>
                <StyledInput
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  onChange={onChangeUsername}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input your password!' }]}>
                <StyledInput
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                  onChange={onChangePassword}
                  type="password"
                />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" onClick={moveToForgotPage}>
                  Forgot password
                </a>
              </Form.Item>

              <Form.Item>
                <LoginButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className="login-form-button">
                  Log in
                </LoginButton>
              </Form.Item>

              <div className="form-group">
                <SignUpWithButton loading={loading} theme={theme.facebook}>
                  <a onClick={handleFBLogin}>Continue with Facebook</a>
                </SignUpWithButton>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </>
        )}
        {page === 'forgot' && (
          <>
            <BackArrow>
              <LeftOutlined onClick={onBackToLogin} />
            </BackArrow>
            <Header>Forgot you password? No worrries!</Header>
            <Form
              name="forgotPass"
              className="forgot-form"
              onFinish={handleForgot}
              layout="vertical"
              form={forgotForm}>
              <Form.Item
                name="email"
                label="email"
                rules={[
                  { required: true, message: 'Please input your Email!' },

                  { type: 'email' }
                ]}>
                <StyledInput
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                <LoginButton
                  type="primary"
                  htmlType="submit"
                  disabled={forgotDisabled}
                  loading={forgotLoading}
                  className="login-form-button">
                  Recover
                </LoginButton>
              </Form.Item>

              {forgotMessage && (
                <div className="form-group">
                  <div
                    className={
                      forgotMessage.successful ? 'alert alert-success' : 'alert alert-danger'
                    }
                    role="alert">
                    {forgotMessage.message}
                  </div>
                </div>
              )}
            </Form>
          </>
        )}
      </LoginWrap>
    </StyledModal>
  );
};

export default Login;
