import React, { createElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductService from 'services/product.service';
import { useMediaQuery } from 'react-responsive';
import { Avatar, Comment, Tooltip, Col, Row } from 'antd';
import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import Product from './Product';
import Products from './Products';
import CreateProductButton from './CreateProductButton';
const StyledComment = styled(Comment)`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
`;
const TopOfListContainer = styled.div`
  width: 100%;

  flex: 1;
  padding: 10px;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;

const SidePanel = styled.div`
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-left: 10px;
`;

const SidePanelTitle = styled.div`
  height: 40px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
  background: #f6f9fa;
  line-height: 40px;
  padding-left: 10px;
`;

const StyledCreateProductButton = styled(CreateProductButton)`
  display: flex;
`;

const ProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);
  const isMobile = useMediaQuery({ query: ' (max-width: 768px)' });

  const like = () => {
    setLikes(1);
    setDislikes(0);
    setAction('liked');
  };

  const dislike = () => {
    setLikes(0);
    setDislikes(1);
    setAction('disliked');
  };
  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{likes}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(action === 'disliked' ? DislikeFilled : DislikeOutlined)}
        <span className="comment-action">{dislikes}</span>
      </span>
    </Tooltip>,
    <span key="comment-basic-reply-to">Reply to</span>
  ];
  useEffect(() => {
    ProductService.getById({ productId }).then(
      (response) => {
        setProduct(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        setProduct(_content);
      }
    );
  }, []);
  if (!currentUser) {
    // return;
  }

  return (
    <>
      <Row>
        <Col span={isMobile ? 24 : 17}>
          <div className="product">
            {product && <Product key={product._id} item={product} isProductPage></Product>}
          </div>
          <div className="product-reviews">
            {product && product.reviews.map
              ? product.reviews.map((item, i) => {
                  return (
                    <StyledComment
                      key={`review_${i}`}
                      actions={actions}
                      author={
                        <>
                          <a href={`/users/${item?.user[0]?._id}`}>
                            {item?.user[0]?.firstname} {item?.user[0]?.lastname}
                          </a>
                          <span>&nbsp;in&nbsp;</span>
                          <a href={`/lists/${item._id}`}>{item.name}</a>
                        </>
                      }
                      avatar={
                        <Avatar
                          src={item?.user[0]?.profileImage}
                          alt={`${item?.user[0]?.firstname} ${item?.user[0]?.lastname}`}>
                          {item?.user[0]?.firstname[0]}
                          {item?.user[0]?.lastname[0]}
                        </Avatar>
                      }
                      content={<p>{item.products.comment}</p>}
                    />
                  );
                })
              : null}
          </div>
        </Col>
        <Col span={isMobile ? 24 : 7}>
          <SidePanel>
            <SidePanelTitle>Related products</SidePanelTitle>
            <Products
              title=""
              excludeProduct={product?._id}
              category={(product?.categories || [product?.category])[0] || ''}
              searchTerm=""
              noPagination
              numOfItems={4}></Products>
          </SidePanel>
        </Col>
      </Row>
    </>
  );
};

export default ProductPage;
