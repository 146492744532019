import axios from 'axios';
import authHeader, { userToken } from './auth-header';

const API_URL =
  (process.env.REACT_APP_API_BASE === ''
    ? 'http://localhost:3020/'
    : process.env.REACT_APP_API_BASE) + 'users/';

const register = ({ firstname, lastname, email, password, inviteCode }) => {
  return axios
    .post(API_URL + 'register', {
      firstname,
      lastname,
      email,
      password,
      inviteCode
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const login = (email, password) => {
  return axios
    .post(API_URL + 'login', {
      email,
      password
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const refreshToken = () => {
  return axios
    .post(
      API_URL + 'refreshToken',
      {
        token: userToken()
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data.data));
      }

      return response.data.data;
    });
};

const loginWithFBToken = (accessToken) => {
  return axios
    .post(API_URL + 'loginWithFBToken', {
      accessToken
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const registerWithFBToken = (accessToken) => {
  return axios
    .post(API_URL + 'registerWithFBToken', {
      accessToken
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
};

export default {
  register,
  login,
  loginWithFBToken,
  registerWithFBToken,
  logout,
  refreshToken
};
