import { Avatar, Dropdown, Menu, Input, Drawer } from 'antd';
import styled from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined, MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { logout } from 'actions/auth';
import { clearMessage } from 'actions/message';

import { history } from 'helpers/history';

// import AuthVerify from "./common/AuthVerify";
import EventBus from 'common/EventBus';
import { setLoginModal, setRegisterModal } from 'actions/modals';

const { Search } = Input;
const StyledSearch = styled(Search)`
  width: 886px;
  height: 53px;

  border-radius: 5px;
  border-collapse: separate;
  .ant-input-affix-wrapper {
    height: 53px;
  }

  .ant-input-search {
    border-radius: 5px !important;
    border-collapse: separate;
  }
  .ant-input-group-addon {
    background: #ebb813;
  }
  .ant-btn-primary {
    background: #ebb813;
    border: 0px;
  }
`;
const HeaderWrap = styled.div`
  background: #4c2857;
  height: 130px;
`;

const TopMenu = styled.div`
  background: #3c3c3c;

  height: 40px;
  color: #d9d9d9;
  padding-left: 20px;

  ul {
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0;
    height: 20px;
    align-items: center;
  }
  li {
    list-style: none;
    padding-left: 15px;
    height: 20px;
    line-height: 20px;
  }
  a {
    color: #d9d9d9;
    line-height: 20px;
    font-size: 16px;
  }
`;
const MainMenu = styled.div`
  background: #4c2857;
  height: 90px;
  padding-top: 16px;
  padding-left: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const LogoLink = styled(Link)`
  margin-right: 15px;
`;
const RightMenu = styled.div`
  justify-self: flex-end;
  padding-right: 16px;
  margin-left: 15px;
`;

const StyledMenu = styled(Menu)`
  min-width: 200px;
  border-radius: 10px;
  padding: 5px 0px;
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item:hover {
    background-color: 'red' !important;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 200px !important;
  }
`;

export const Header = ({ searchTerm, setSearchTerm }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: ' (max-width: 768px)' });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const setSearchTermAndRedirect = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = useCallback(() => {
    setDrawerOpen(false);
    dispatch(logout());
  }, [dispatch]);

  const showLoginModal = useCallback(() => {
    dispatch(setLoginModal(true));
  }, [dispatch]);
  const showRegisterModal = useCallback(() => {
    dispatch(setRegisterModal(true));
  }, [dispatch]);
  const menuItems = [
    {
      label: (
        <Link to={'/profile/info'} key="profile-link" onClick={onCloseDrawer}>
          Profile
        </Link>
      ),
      key: '0'
    },
    {
      label: (
        <Link to={'/profile/lists'} key="lists-link" onClick={onCloseDrawer}>
          Lists
        </Link>
      ),
      key: '1'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <Link to="/" key="logout-link" onClick={logOut}>
          Logout
        </Link>
      ),
      key: '3'
    }
  ];
  const menu = <StyledMenu items={menuItems} />;

  useEffect(() => {
    EventBus.on('logout', () => {
      logOut();
    });

    return () => {
      EventBus.remove('logout');
    };
  }, [currentUser, logOut]);
  const onSearch = (value) => {
    setSearchTerm(value);
    navigate('/products');
  };
  return (
    <HeaderWrap>
      <TopMenu>
        {currentUser ? (
          <ul className="">
            <li className="">
              <Link to={'/products'} className="">
                Products
              </Link>
            </li>
            <li className="">
              <Link to={'/products?featured'} className="">
                Featured
              </Link>
            </li>
          </ul>
        ) : (
          <ul className="">
            <li className="">
              <a href="#login" onClick={showLoginModal}>
                Login
              </a>
            </li>

            <li className="">
              <a href="#register" onClick={showRegisterModal}>
                Sign Up
              </a>
            </li>
          </ul>
        )}
      </TopMenu>
      <MainMenu>
        <LogoLink to={'/'}>
          <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_14_53)">
              <path
                d="M26.4993 0C11.8649 0 0 11.8649 0 26.4993C0 41.1338 11.8649 52.9986 26.4993 52.9986C41.1338 52.9986 52.9986 41.1338 52.9986 26.4993C52.9986 11.8649 41.1352 0 26.4993 0ZM26.4993 36.812C30.0044 36.812 33.2395 35.0533 35.1538 32.1088L41.2743 36.0862C38.0088 41.1104 32.4848 44.11 26.4993 44.11C20.5139 44.11 14.9898 41.1104 11.7244 36.0848L9.7329 33.0219L32.6073 18.185C30.8638 16.9014 28.7359 16.1852 26.4993 16.1852C22.9943 16.1852 19.7591 17.944 17.8448 20.8885L11.7244 16.911C14.9898 11.8869 20.5139 8.8859 26.4993 8.8859C32.4848 8.8859 38.0075 11.8855 41.2743 16.9096L43.2657 19.9726L20.3913 34.8095C22.1349 36.0931 24.2627 36.8093 26.4993 36.8093V36.812Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_14_53">
                <rect width="53" height="53" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </LogoLink>

        {currentUser ? (
          <StyledSearch
            placeholder="input search text"
            allowClear
            enterButton={<SearchOutlined />}
            size="large"
            onSearch={onSearch}
          />
        ) : null}
        <RightMenu>
          {currentUser ? (
            isMobile ? (
              <>
                <MenuOutlined style={{ fontSize: '45px', color: '#fff' }} onClick={showDrawer} />
                <StyledDrawer
                  closable={false}
                  visible={drawerOpen}
                  title={`Hey, ${currentUser?.firstname}`}
                  onClose={onCloseDrawer}>
                  {menuItems.map((item) => {
                    return (
                      <div key={item.key}>{item.type === 'divider' ? <hr /> : item.label}</div>
                    );
                  })}
                </StyledDrawer>
              </>
            ) : (
              <Dropdown overlay={menu}>
                <Avatar
                  size={50}
                  src={currentUser?.profileImage}
                  style={{
                    textTransform: 'uppercase',
                    color: '#f56a00',
                    backgroundColor: '#fde3cf'
                  }}>
                  {!currentUser?.profileImage &&
                    `${currentUser?.firstname[0]}${currentUser?.lastname[0]}`}
                </Avatar>
              </Dropdown>
            )
          ) : null}
        </RightMenu>
      </MainMenu>
    </HeaderWrap>
  );
};
