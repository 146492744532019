import React, { useRef, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Form, Input, Image, Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import ImageUpdate from './ImageUpload';
import playgroundService from 'services/playground.service';
import { ArrowRightOutlined, ArrowDownOutlined } from '@ant-design/icons';

const MainWrap = styled.div`
  background: #f5f5f5;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  max-width: 1000px;
  @media only screen and (max-width: 768px) {
    width: 95%;
    margin: 5px 2.5%;
  }
  padding: 16px;
`;

const ImagesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
`;

const UpdateButton = styled(Button)`
  background: #656565;
  height: 40px;
  color: #fff;
  border-radius: 20px;
  border: 0px;
  font-weight: 600;

  margin-left: auto;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  &.ant-btn:hover {
    background: #656565;
    opacity: 0.8;
    color: #fff;
  }
`;

const Playground = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery({ query: ' (max-width: 768px)' });
  const form = useRef();
  const [resultImage, setResultImage] = useState('');
  const [image, setImage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!currentUser) {
    return;
  }

  const onImageUpdate = (image) => {
    setImage(image);
  };

  const onImageDrop = (image) => {
    setImage('');

    setResultImage('');
  };
  const onFinish = (values) => {
    setIsSubmitting(true);
    playgroundService
      .generateImage({ imageUrl: image, prompt: values.prompt, title: values.title })
      .then((res) => {
        console.log(res);
        setResultImage(res.data.data[1]);
        console.log(resultImage);
        setIsSubmitting(false);
      });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  };
  return (
    <MainWrap>
      <Row>
        <Col span={isMobile ? 24 : 24}>
          <Form
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            ref={form}>
            <Form.Item name={['image']} label="Original image:">
              <ImagesWrap>
                <ImageUpdate onImageUpdate={onImageUpdate} onImageDrop={onImageDrop} />
                {isMobile ? (
                  <ArrowDownOutlined style={{ margin: '10px auto' }} />
                ) : (
                  <ArrowRightOutlined style={{ margin: '10px' }} />
                )}
                <a href={resultImage} target={'_blank'} download rel="noreferrer">
                  <Image
                    width={245}
                    src={resultImage}
                    preview={false}
                    style={{
                      color: '#f56a00',
                      backgroundColor: '#fde3cf'
                    }}></Image>
                </a>
              </ImagesWrap>
            </Form.Item>
            <Form.Item name={['prompt']} label="Prompt">
              <Input.TextArea placeholder="animated" />
            </Form.Item>

            <div style={{ display: 'flex' }}>
              <UpdateButton loading={isSubmitting} disabled={image === ''} htmlType="submit">
                Submit
              </UpdateButton>
            </div>
          </Form>
        </Col>
      </Row>
    </MainWrap>
  );
};

export default Playground;
