import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { Spin, Row, Col, Avatar } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import UserLists from 'components/Profile/UserLists';
import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from 'next-share';
import { useSelector } from 'react-redux';
import ListService from 'services/list.service';
import ListProducts from './ListProducts';
import CreateProductButton from 'components/Products/CreateProductButton';

const ListImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
`;

const StyledCreateProductButton = styled(CreateProductButton)`
  display: flex;
`;

const SidePanel = styled.div`
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-left: 10px;
`;

const SidePanelTitle = styled.div`
  height: 40px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
  background: #f6f9fa;
  line-height: 40px;
  padding-left: 10px;
`;

const ListTopInfo = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
  justify-content: space-between;
  border-bottom: 1px dashed #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const LeftData = styled.div`
  justify-content: flex-start;
  padding-left: 10px;
`;

const RightData = styled.div`
  justify-content: flex-end;
`;
const ListTitle = styled.h1`
  display: inline-block;
  line-height: 30px;
  padding: 0px;
`;
const EditLink = styled(Link)`
  padding-left: 10px;
  height: 38px;
  display: inline-block;
  .anticon {
    padding: 4px;
    border: 1px solid #e6e6e6;
    border-radius: 20px;
    background: #f5f5f5;
    color: #4d4d4d;
  }
`;

const TitleWithEditLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListPage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [error, setError] = useState(null);
  const { listId } = useParams();
  const [list, setList] = useState(null);
  const isMobile = useMediaQuery({ query: ' (max-width: 768px)' });
  useEffect(() => {
    ListService.getListById({ listId }).then(
      (response) => {
        setList(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        setError('Error');
      }
    );
  }, []);
  const shareUrl = window.location.href;
  const shareText = 'See the list i created on Zelisto';
  if (error) {
    return <div>404</div>;
  }
  if (!list) {
    return (
      <div>
        <Spin />
      </div>
    );
  }
  return (
    <div>
      <Row>
        <Col span={isMobile ? 24 : 17}>
          <div>
            {list && (
              <>
                <ListImage src={list.mainImage} alt={list.name} />
                <ListTopInfo>
                  <LeftData>
                    <TitleWithEditLink>
                      {list && <ListTitle>{list.name}</ListTitle>}
                      {list && list?.userId._id === currentUser?.id ? (
                        <EditLink to={`/lists/${list._id}/edit`}>
                          <EditFilled />
                        </EditLink>
                      ) : (
                        ''
                      )}
                    </TitleWithEditLink>
                    <a href={`/users/${list.userId._id}`}>
                      <Avatar src={list?.userId.profileImage} alt="Ady Levy">
                        {list.userId?.firstname[0]}&nbsp;{list.userId?.lastname[0]}
                      </Avatar>
                      &nbsp;By {list.userId.firstname}&nbsp;
                      {list.userId.lastname}
                    </a>
                  </LeftData>
                  <RightData>
                    <div>
                      <FacebookShareButton
                        style={{ margin: '0px 0px 5px 5px' }}
                        url={shareUrl}
                        quote={shareText}
                        hashtag={'#zelisto'}>
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <FacebookMessengerShareButton
                        style={{ margin: '0px 0px 5px 5px' }}
                        url={shareUrl}
                        quote={shareText}
                        appId={''}>
                        <FacebookMessengerIcon size={32} round />
                      </FacebookMessengerShareButton>
                      <WhatsappShareButton
                        style={{ margin: '0px 0px 5px 5px' }}
                        url={shareUrl}
                        quote={shareText}
                        hashtag={'#zelisto'}>
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <EmailShareButton
                        style={{ margin: '0px 0px 5px 5px' }}
                        url={shareUrl}
                        subject={shareText}
                        body="body">
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                      <TelegramShareButton
                        style={{ margin: '0px 0px 5px 5px' }}
                        url={shareUrl}
                        quote={shareText}>
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>
                      <TwitterShareButton
                        style={{ margin: '0px 0px 5px 5px' }}
                        url={shareUrl}
                        title={shareText}>
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>

                      {list && list?.userId._id === currentUser?.id ? (
                        <div>
                          <StyledCreateProductButton />
                        </div>
                      ) : null}
                    </div>
                  </RightData>
                </ListTopInfo>
              </>
            )}
          </div>
          <ListProducts listId={listId} />
        </Col>
        <Col span={isMobile ? 24 : 7}>
          <SidePanel>
            <SidePanelTitle>Related Lists</SidePanelTitle>
            <UserLists featured numOfItems={4} noPagination title={''}></UserLists>
          </SidePanel>
        </Col>
      </Row>
    </div>
  );
};

export default ListPage;
