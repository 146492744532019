import { SET_LOGIN_MODAL, SET_REGISTER_MODAL } from '../actions/types';

const initialState = {
  loginModal: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOGIN_MODAL:
      return { loginModal: payload };
    case SET_REGISTER_MODAL:
      return { registerModal: payload };
    default:
      return state;
  }
}
