import loadImage from 'blueimp-load-image';

export const getUploadId = (asset) => asset.name && asset.name.replace(/\s/g, '-').toLowerCase();

export const getRotatedFile = (file) => {
  return new Promise((resolve) => {
    loadImage(
      file,
      (canvas, data) => {
        resolve(canvas.toDataURL());
      },
      { orientation: true, canvas: true }
    );
  });
};
