import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button, Card, Tag } from 'antd';
import { AmazonOutlined } from '@ant-design/icons';

import AddToListButton from 'components/Lists/AddToListButton';

import { AliIcon } from 'common/icons';
const Price = styled.span`
  max-width: 30%;
  color: #d33a3a;
`;

const OriginIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
`;

const BuyNowTag = styled(Button)`
  max-width: 30%;
  background-color: #4c2857;
  color: #fff;
  border-radius: 10px;
  border: 0px;
  font-weight: 600;
  align-self: flex-end;
  margin-left: auto;

  &.ant-btn:hover,
  &.ant-btn:focus {
    background-color: #4c2857;
    color: #fff;
    opacity: 0.9;
  }
`;

const ProductContainer = styled.div`
  border: 1px solid #cfcfcf;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  width: 48%;
  margin: 1%;
  height: 200px;
  border-radius: 8px;
  display: flex;
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Cover = styled.div`
  margin-bottom: 15px;
  margin-top: 10px;
  background: #fff;
`;
const Image = styled.img`
  object-fit: contain;
  max-height: 235px;
  height: 235px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled.div`
  width: 300px;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background: #ededed;
  font-size: 14px;
`;

const Metadata = styled.p`
  height: 50px;
  overflow: hidden;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
`;

const ItemPrice = ({ item }) => {
  if (item.inventoryStatus === 0) {
    return <>Out of Stock</>;
  }
  return (
    <>
      {item.currency}
      {item.price || '$$$'}&nbsp;
      <OriginalPrice>
        {' '}
        {item.currency}
        {item.originalPrice || '$$$'}
      </OriginalPrice>
    </>
  );
};

const Product = ({ item, isProductPage }) => {
  return (
    <StyledCard>
      <Row>
        <AddToListButton productId={item._id} />
      </Row>
      <Cover>
        <Image src={item.thumbailUrl} width="100%" alt={item.title} />
      </Cover>
      <Metadata>{item.title}</Metadata>
      <Price>
        <ItemPrice item={item} />
      </Price>
      <Row>
        {item.tags}
        <OriginIcon>{item.itemOrigin === 'amazon' ? <AmazonOutlined /> : <AliIcon />}</OriginIcon>
        <BuyNowTag>
          {isProductPage ? (
            <a href={item.productUrl} target="_blank" rel="nofollow noreferrer">
              Buy Now
            </a>
          ) : (
            <a href={`/product/${item._id}`} target="" rel="">
              Buy Now
            </a>
          )}
        </BuyNowTag>
      </Row>
    </StyledCard>
  );
};

export default Product;
