import { SET_REGISTER_MODAL, SET_LOGIN_MODAL } from './types';

export const setLoginModal = (payload) => ({
  type: SET_LOGIN_MODAL,
  payload
});

export const setRegisterModal = (payload) => ({
  type: SET_REGISTER_MODAL,
  payload
});
