import axios from 'axios';
import authHeader from './auth-header';

const API_URL =
  (process.env.REACT_APP_API_BASE === ''
    ? 'http://localhost:3020/'
    : process.env.REACT_APP_API_BASE) + 'lists/';

const getUserLists = ({ userId, page, perPage, featured }) => {
  return axios.get(
    `${API_URL}user?featured=${featured || false}&userId=${
      userId || ''
    }&page=${page}&perPage=${perPage}`,
    {
      headers: authHeader()
    }
  );
};

const getFeatured = ({ page, perPage }) => {
  return axios.get(`${API_URL}featured?page=${page}&perPage=${perPage}`, {
    headers: authHeader()
  });
};

const getListById = ({ listId }) => {
  return axios.get(`${API_URL}${listId}`, { headers: authHeader() });
};

const createList = ({ name, productId, comment }) => {
  return axios
    .post(
      API_URL + '',
      {
        name,
        productId,
        comment
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addToList = ({ listId, productId, comment }) => {
  return axios
    .put(
      API_URL + 'add',
      {
        listId,
        productId,
        comment
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteList = (listId) => {
  return axios
    .delete(API_URL + listId, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const removeFromList = ({ listId, productId }) => {
  return axios
    .put(
      API_URL + 'remove',
      {
        listId,
        productId
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateListMainImage = ({ listId, url }) => {
  return axios.put(
    API_URL + `${listId}/updateMainImage`,
    { mainImage: url },
    { headers: authHeader() }
  );
};

const updateProductComment = ({ listId, productId, comment }) => {
  return axios.put(
    API_URL + `${listId}/updateProductComment`,
    { productId, comment },
    { headers: authHeader() }
  );
};

const updateList = ({ listId, list }) => {
  return axios.put(API_URL + `${listId}`, { ...list }, { headers: authHeader() });
};

export default {
  getUserLists,
  getFeatured,
  createList,
  addToList,
  removeFromList,
  getListById,
  deleteList,
  updateListMainImage,
  updateList,
  updateProductComment
};
