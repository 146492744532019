import axios from 'axios';
import authHeader from './auth-header';

const API_URL =
  (process.env.REACT_APP_API_BASE === ''
    ? 'http://localhost:3020/'
    : process.env.REACT_APP_API_BASE) + 'scrapedUrl/';
const getScrapedUrlPreview = (url) => {
  return axios.get(API_URL + '?url=' + encodeURIComponent(url), { headers: authHeader() });
};

export default {
  getScrapedUrlPreview
};
